<template>
    <div>
         <div class="home-pc">
               <div class="shuziren">
                    <div class="shuziren-banner">
                        <img src="@/assets/image/banner2.png" alt="" style="width:100%;height:100%">
                    </div>
                    <div class="shuziren-ying">
                        <div class="ying-wenzi">
                            <p>应用场景</p>
                            <div class="row1">
                            <div class="col1">
                                <div class="dian-ziti" @mouseenter="gety()" @mouseleave ="getyc()">
                                    <div class="ziti-wen">
                                        <div class="wen-img">
                                            <img src="@/assets/image/zhan1.png" alt="" v-if="falg == 0">
                                             <img src="@/assets/image/zhan12.png" alt="" v-else>
                                            <span>展厅</span>
                                        </div>
                                        <div class="wen-zi">
                                            作为讲解员为参观者提供导览和咨询服务，讲解过程中播放视频或其他内容
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col1">
                            <div class="dian-ziti" @mouseenter="gety1()" @mouseleave ="getyc1()">
                                    <div class="ziti-wen">
                                        <div class="wen-img">
                                            <img src="@/assets/image/shang.png" alt="" v-if="falg1 ==0">
                                            <img src="@/assets/image/shang12.png" alt="" v-else>
                                            <span>商场</span>
                                        </div>
                                        <div class="wen-zi">
                                            为消费者提供导航、信息咨询服务，可结合商家宣传需要个性化展示和内容宣传
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col1">
                            <div class="dian-ziti" @mouseenter="gety2()" @mouseleave ="getyc2()">
                                    <div class="ziti-wen">
                                        <div class="wen-img">
                                            <img src="@/assets/image/yi.png" alt="" v-if="falg2 ==0">
                                            <img src="@/assets/image/yi12.png" alt="" v-else>
                                            <span>医疗</span>
                                        </div>
                                        <div class="wen-zi">
                                            在医疗服务场景以真人形象提供医院事务咨询、顾问、陪伴等服务，减轻医护负担
                                        </div>
                                    </div>
                                </div>
                            </div>
                                </div>
                            <div class="row1" style="margin-top:30px">
                            <div class="col1">
                                <div class="dian-ziti" @mouseenter="gety3()" @mouseleave ="getyc3()">
                                    <div class="ziti-wen">
                                        <div class="wen-img">
                                            <img src="@/assets/image/jin.png" alt="" v-if="falg3 ==0">
                                            <img src="@/assets/image/jin12.png" alt="" v-else>
                                            <span>金融</span>
                                        </div>
                                        <div class="wen-zi">
                                            用于金融服务机构，作为数字员工，对于客户提供咨询、客服等方面，让服务智能高效
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col1">
                            <div class="dian-ziti" @mouseenter="gety4()" @mouseleave ="getyc4()">
                                    <div class="ziti-wen">
                                        <div class="wen-img">
                                            <img src="@/assets/image/wen.png" alt="" v-if="falg4 ==0">
                                            <img src="@/assets/image/wen12.png" alt="" v-else>
                                            <span>文旅</span>
                                        </div>
                                        <div class="wen-zi">
                                            博物馆、景区中为游客做景点介绍、问题解答、路线指引、旅行建议，更好服务游客
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col1">
                            <div class="dian-ziti" @mouseenter="gety5()" @mouseleave ="getyc5()">
                                    <div class="ziti-wen">
                                        <div class="wen-img">
                                            <img src="@/assets/image/zheng.png" alt="" v-if="falg5 ==0">
                                            <img src="@/assets/image/zheng12.png" alt="" v-else>
                                            <span>政务</span>
                                        </div>
                                        <div class="wen-zi">
                                            用于政务大厅、数字乡村、智慧社区等，能随时为群众答疑和事务办理提供指导  
                                        </div>
                                    </div>
                                </div>
                            </div>
                          </div>
                        </div>
                    </div>
                    <div class="dayuyan">
                        <div class="dayuyan-wenzi">
                            行业个性化数智人整体解决方案
                        </div>
                        <div class="danyuyan-yijian">
                            根据客户的应用场景提供包含大模型、Agent、3D数字人整套的个性化解决方案
                        </div>
                        <div style="text-align:center;margin-top:50px">
                            <img src="@/assets/image/shuo1.png" alt="">
                        </div>
                    </div>
                    <div class="zhichi">
                        <div class="zhichi-wenzi">
                            超写实3D数字人形象和语音定制
                        </div>
                        <div class="zhichi-yijian">
                            根据应用场景定制3D数字人形象、相关的动作、展示内容和背景，可以根据形象选择配套语音，支持声音克隆，让整体形象符合场景的需要
                        </div>
                        <div style="text-align:center;margin-top:50px">
                            <img src="@/assets/image/shuo2.png" alt="">
                        </div>
                    </div>
                    <div class="duo">
                        <div class="duo-wenzi">
                            大语言模型训练让数智人更专业
                        </div>
                        <div class="duo-yijian">
                           基于客户行业应用场景需要数智人完成的工作，对于大模型和知识库进行专门训练，确保数字人面对各类专业问题，能够准确的提供答复
                        </div>
                        <div style="text-align:center;margin-top:50px">
                            <img src="@/assets/image/shuo3.png" alt="">
                        </div>
                    </div>
                    <div class="hui">
                        <div class="hui-wenzi">
                            Agent让数智人与行业场景深度融合
                        </div>
                        <div class="hui-yijian">
                             支持定制沟通流程，通过定制关键词设置触发对应接口调用，便于数字人与具体场景和业务系统进行联动和内容展示
                        </div>
                        <div style="text-align:center;margin-top:50px">
                            <img src="@/assets/image/shuo5.png" alt="">
                        </div>
                    </div>
                </div>
        </div>
        <div class="home-yidong">
              <div class="shuziren">
                    <div class="shuziren-banner">
                        <img src="@/assets/image/banner2.png" alt="" style="width:100%;height:100%">
                    </div>
                    <div class="shuziren-ying">
                        <div class="ying-wenzi">
                            <p style="font-size:20px">应用场景</p>
                            <div class="row1" style="display:flex; flex-wrap: wrap;">
                            <div class="col1">
                                <div class="dian-ziti">
                                    <div class="ziti-wen">
                                        <div class="wen-img">
                                            <img src="@/assets/image/zhan1.png" alt="">
                                            <span>展厅</span>
                                        </div>
                                        <div class="wen-zi">
                                            作为讲解员为参观者提供导览和咨询服务，讲解过程中播放视频或其他内容
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col1">
                            <div class="dian-ziti">
                                    <div class="ziti-wen">
                                        <div class="wen-img">
                                            <img src="@/assets/image/shang.png" alt="">
                                            <span>商场</span>
                                        </div>
                                        <div class="wen-zi">
                                            为消费者提供导航、信息咨询服务，可结合商家宣传需要个性化展示和内容宣传
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col1">
                            <div class="dian-ziti">
                                    <div class="ziti-wen">
                                        <div class="wen-img">
                                            <img src="@/assets/image/yi.png" alt="">
                                            <span>医疗</span>
                                        </div>
                                        <div class="wen-zi">
                                            在医疗服务场景以真人形象提供医院事务咨询、顾问、陪伴等服务，减轻医护负担
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col1">
                                <div class="dian-ziti">
                                    <div class="ziti-wen">
                                        <div class="wen-img">
                                            <img src="@/assets/image/jin.png" alt="">
                                            <span>金融</span>
                                        </div>
                                        <div class="wen-zi">
                                            用于金融服务机构，作为数字员工，对于客户提供咨询、客服等方面，让服务智能高效
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col1">
                            <div class="dian-ziti">
                                    <div class="ziti-wen">
                                        <div class="wen-img">
                                            <img src="@/assets/image/wen.png" alt="">
                                            <span>文旅</span>
                                        </div>
                                        <div class="wen-zi">
                                            博物馆、景区中为游客做景点介绍、问题解答、路线指引、旅行建议，更好服务游客
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col1">
                            <div class="dian-ziti">
                                    <div class="ziti-wen">
                                        <div class="wen-img">
                                            <img src="@/assets/image/zheng.png" alt="">
                                            <span>政务</span>
                                        </div>
                                        <div class="wen-zi">
                                            用于政务大厅、数字乡村、智慧社区等，能随时为群众答疑和事务办理提供指导 
                                        </div>
                                    </div>
                                </div>
                            </div>
                                </div>
                            
                        </div>
                    </div>
                    <div class="dayuyan">
                        <div class="dayuyan-wenzi">
                            行业个性化数智人整体解决方案
                        </div>
                        <div class="danyuyan-yijian">
                            根据客户的应用场景提供包含大模型、Agent、3D数字人整套的个性化解决方案
                        </div>
                        <div style="text-align:center;margin-top:50px">
                            <img src="@/assets/image/shuo1.png" alt="" style="width:80%">
                        </div>
                    </div>
                    <div class="zhichi">
                        <div class="zhichi-wenzi">
                            超写实3D数字人形象和语音定制
                        </div>
                        <div class="zhichi-yijian">
                            根据应用场景定制3D数字人形象、相关的动作、展示内容和背景，可以根据形象选择配套语音，支持声音克隆，让整体形象符合场景的需要
                        </div>
                        <div style="text-align:center;margin-top:50px">
                            <img src="@/assets/image/shuo2.png" alt="" style="width:80%">
                        </div>
                    </div>
                    <div class="duo">
                        <div class="duo-wenzi">
                            大语言模型训练让数智人更专业
                        </div>
                        <div class="duo-yijian">
                           基于客户行业应用场景需要数智人完成的工作，对于大模型和知识库进行专门训练，确保数字人面对各类专业问题，能够准确的提供答复
                        </div>
                        <div style="text-align:center;margin-top:50px">
                            <img src="@/assets/image/shuo3.png" alt="" style="width:80%">
                        </div>
                    </div>
                    <div class="hui">
                        <div class="hui-wenzi">
                            Agent让数智人与行业场景深度融合
                        </div>
                        <div class="hui-yijian">
                             支持定制沟通流程，通过定制关键词设置触发对应接口调用，便于数字人与具体场景和业务系统进行联动和内容展示
                        </div>
                        <div style="text-align:center;margin-top:50px">
                            <img src="@/assets/image/shuo5.png" alt="" style="width:80%">
                        </div>
                    </div>
                </div>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return{
            falg:0,
            falg1:0,
            falg2:0,
            falg3:0,
            falg4:0,
            falg5:0,
        }
    },
    methods:{
        gety(){
            this.falg = 1
        },
        getyc(){
            this.falg = 0
        },
        gety1(){
            this.falg1 = 1
        },
        getyc1(){
            this.falg1 = 0
        },
        gety2(){
            this.falg2 = 1
            console.log(this.falg)
        },
        getyc2(){
            this.falg2 = 0
        },
        gety3(){
            this.falg3 = 1
            console.log(this.falg)
        },
        getyc3(){
            this.falg3 = 0
        },
        gety4(){
            this.falg4 = 1
            console.log(this.falg)
        },
        getyc4(){
            this.falg4 = 0
        },
        gety5(){
            this.falg5 = 1
            console.log(this.falg)
        },
        getyc5(){
            this.falg5 = 0
        }
    }
}
</script>
<style lang="scss">
    .home-pc{
        .shuziren{
        .shuziren-ying{
            height: 550px;
            background-color: #DCEBFF;
            .ying-wenzi{
                padding-top: 40px;
                p{
                    font-size: 40px;
                    color: #000000;
                    text-align: center;
                    margin-bottom: 30px;
                }
                .row1{
                        width: 1270px;
                        display: flex;
                        justify-content: space-between;
                        margin: 0 auto;
                    .dian-ziti:hover{
                            background-color: #0E76FF;
                            cursor: pointer;
                            span{
                                color: #fff !important;
                            }
                            .wen-zi{
                                color: #C3DDFF !important;
                            }
                        }
                    .dian-ziti{
                        width: 400px;
                        height: 167px;
                        background: #FFFFFF;
                        border: 1px solid #E9F0F3;
                        border-radius: 10px;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        .ziti-wen{
                            padding: 0 15px;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            .wen-img{
                                display: flex;
                                align-items: center;
                            }
                            span{
                                font-size: 18px;
                                color: #000000;
                                margin-left: 10px;
                            }
                        }
                        .wen-zi{
                            margin-top: 10px;
                            font-size: 18px;
                            color: #919191;
                        }
                    }
                }
                .ying-dian1{
                    display: flex;
                    justify-content: center;
                    margin-top: 40px;
                    .dian-ziti{
                        width: 400px;
                        height: 167px;
                        background: #FFFFFF;
                        border: 1px solid #E9F0F3;
                        border-radius: 10px;
                        margin-right: 40px;
                    }
                }
            }
        }
        .dayuyan{
                height: 650px;
                background-color: #F5FCFF;
                .dayuyan-wenzi{
                    font-size: 40px;
                    color: #262A47;
                    text-align: center;
                    padding-top: 40px;
                }
                .danyuyan-yijian{
                    font-size: 20px;
                    color: #262A47;
                    text-align: center;
                }
         }
         .zhichi{
             height:800px;
             background-color: #DCEBFF;
                .zhichi-wenzi{
                    font-size: 40px;
                    color: #262A47;
                    text-align: center;
                    padding-top: 40px;
                }
                .zhichi-yijian{
                    font-size: 20px;
                    color: #262A47;
                    width: 1240px;
                    margin: 0 auto;
                    margin-top: 20px;
                }
         }
        .duo{
                height: 840px;
                background-color: #F5FCFF;
                .duo-wenzi{
                    font-size: 40px;
                    color: #262A47;
                    text-align: center;
                    padding-top: 40px;
                }
                .duo-yijian{
                    font-size: 20px;
                    color: #262A47;
                    width: 1240px;
                    margin: 0 auto;
                    margin-top: 20px;
                }
         }
            .hui{
             height:800px;
             background-color: #DCEBFF;
                .hui-wenzi{
                    font-size: 40px;
                    color: #262A47;
                    text-align: center;
                    padding-top: 40px;
                }
                .hui-yijian{
                    font-size: 20px;
                    color: #262A47;
                    width: 1240px;
                    margin: 0 auto;
                    margin-top: 20px;
                    text-align: center;
                }
         }
      }
    }
    .home-yidong{
        .shuziren{
        .shuziren-ying{
            height: auto !important;
            background-color: #DCEBFF;
            padding-bottom: 30px;
            .ying-wenzi{
                padding-top: 20px;
                p{
                    font-size: 14px;
                    color: #000000;
                    text-align: center;
                    margin-bottom: 20px;
                }
                .row1{
                    width: 350px;
                    margin: 0 auto;
                    display: flex;
                    justify-content: center;
                    .dian-ziti{
                        width: 152px;
                        height: 167px;
                        background: #FFFFFF;
                        border: 1px solid #E9F0F3;
                        border-radius: 10px;
                        margin-right: 20px;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        margin-bottom: 20px;
                        .ziti-wen{
                            padding: 0 15px;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            .wen-img{
                                display: flex;
                                align-items: center;
                            }
                            span{
                                font-size: 16px;
                                color: #000000;
                                margin-left: 10px;
                            }
                        }
                        .wen-zi{
                            margin-top: 10px;
                            font-size: 14px;
                            color: #919191;
                        }
                    }
                }
                .ying-dian1{
                    display: flex;
                    justify-content: center;
                    margin-top: 40px;
                    .dian-ziti{
                        width: 400px;
                        height: 167px;
                        background: #FFFFFF;
                        border: 1px solid #E9F0F3;
                        border-radius: 10px;
                        margin-right: 40px;
                    }
                }
            }
        }
        .dayuyan{
                height: 360px;
                background-color: #F5FCFF;
                .dayuyan-wenzi{
                    font-size: 18px;
                    color: #262A47;
                    text-align: center;
                    padding-top: 40px;
                }
                .danyuyan-yijian{
                    font-size: 14px;
                    color: #262A47;
                    margin:0 20px;
                    line-height: 22px;
                    margin-top: 20px;
                }
         }
         .zhichi{
             height:370px;
             background-color: #DCEBFF;
                .zhichi-wenzi{
                    font-size: 18px;
                    color: #262A47;
                    text-align: center;
                    padding-top: 40px;
                }
                .zhichi-yijian{
                    font-size: 14px;
                    color: #262A47;
                    margin:0 20px;
                    line-height: 22px;
                    margin-top: 20px;
                }
         }
        .duo{
                height: 420px;
                background-color: #F5FCFF;
                .duo-wenzi{
                    font-size: 18px;
                    color: #262A47;
                    text-align: center;
                    padding-top: 40px;
                }
                .duo-yijian{
                    font-size: 14px;
                    color: #262A47;
                    margin:0 20px;
                    line-height: 22px;
                    margin-top: 20px;
                }
         }
            .hui{
             height:430px;
             background-color: #DCEBFF;
                .hui-wenzi{
                    font-size: 18px;
                    color: #262A47;
                    text-align: center;
                    padding-top: 40px;
                }
                .hui-yijian{
                    font-size: 14px;
                    color: #262A47;
                    margin:0 20px;
                    line-height: 22px;
                    margin-top: 20px;
                }
         }
      }
    }

</style>